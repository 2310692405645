var $name="analytics/contact",$path="app/modules/analytics/contact.js",$this={$name,$path,};import getSlugs from 'app/utilities/getSlugs';
import lookupSlugs from 'app/utilities/lookupSlugs';

/**
 * @param {
 * {
 * contactCategory: string,
 * requestTypeURL: string,
 * formId: string,
 * userHashedEmail: string,
 * formObj: Object,
 * form_type: string,
 * actionUrl: string,
 * }
 * } variables
 * @returns
 */
const formTypes = {
  ContactUsbFO: 'ContactUsbFO',
  FooterSubscription: 'FooterSubscription',
  formEmailSingUp: 'formEmailSingUp',
};
const fromCategory = {
  Web2CaseSales: 'Web2CaseSales',
  web2Newsletter: 'Web2Newsletter',
  Web2CaseSupport: 'Web2CaseSupport',
  web2NewsletterMarketo: 'Web2Newsletter-marketo',
  ThirdPartyDataEnrichmentConsent: 'third_party_data_enrichment_consent',
};
const contactSubmitProvider = ({
  requestTypeURL,
  formId,
  userHashedEmail,
  userHashedPhone = '',
  actionUrl = '',
  formObj = {},
}, config) => {
  const { pathname } = new URL(requestTypeURL, window.location.href);
  const formType = lookupSlugs(config.requestType ?? {}, getSlugs(pathname), pathname);

  const THIRD_PARTY_DATA_ENRICHMENT_CONSENT_FORM_URL = 'au/en/about-us/legal/third-party-data-enrichment-consent';
  const currentUrl = window.location.href;

  const formParams = {};

  formParams.user_hashed_email = userHashedEmail;
  formParams.i_am_a = formObj.iama || formObj.Iama;

  if (formId?.includes(formTypes.ContactUsbFO) && actionUrl?.includes('contact-sales')) {
    formParams.form_type = currentUrl.includes(THIRD_PARTY_DATA_ENRICHMENT_CONSENT_FORM_URL)
      ? fromCategory.ThirdPartyDataEnrichmentConsent
      : fromCategory.Web2CaseSales;
    formParams.user_hashed_phone = userHashedPhone;
    formParams.company = formObj.Company || formObj.company;
    formParams.location = formObj.Country || formObj.country || formObj.Location || formObj.location;
    formParams.contactCategory = fromCategory.Web2CaseSales;
  } else if (formId?.includes(formTypes.ContactUsbFO) && !actionUrl?.includes('contact-sales')) {
    formParams.form_type = fromCategory.Web2CaseSupport;
    formParams.user_hashed_phone = userHashedPhone;
    formParams.company = formObj.Company || formObj.company;
    formParams.location = formObj.Country || formObj.country || formObj.Location || formObj.location;
    formParams.contactCategory = fromCategory.Web2CaseSupport;
  } else if (formId?.includes(formTypes.FooterSubscription)) {
    formParams.form_type = fromCategory.web2Newsletter;
    formParams.contactCategory = fromCategory.web2Newsletter;
  } else if (formId?.includes(formTypes.formEmailSingUp)) {
    formParams.form_type = fromCategory.web2NewsletterMarketo;
    formParams.contactCategory = fromCategory.web2NewsletterMarketo;
  }
  return {
    contactCategory: formType,
    ...formParams,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { contactSubmitProvider };
